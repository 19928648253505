@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-Regular.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-Bold.ttf) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-Thin.ttf) format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-ExtraLight.ttf) format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-Light.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-SemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-Bold.ttf) format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-ExtraBold.ttf) format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "MyInter";
  src: local("MyInter"), url(./fonts/Inter-Black.ttf) format("truetype");
  font-weight: 900;
}

body {
  margin: 0;
  font-family: "MyInter", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, h1, h2, h3, h4, h5, h6, button, .ui.button {
  font-family: "MyInter", "Helvetica Neue", sans-serif;
  line-height: 24pt;
  font-weight: 400;
}

html,
body {
  font-family: "MyInter", "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-weight: 400;
}

html, body, div, span, p, .ui.dropdown .menu > .item {
    color: #2c3139;
}

.ui.dropdown .menu > .item {
  line-height: 24pt;
}

a:hover {
    text-decoration: none;
}


span, b, i, strong {
    line-height: unset;
}
