.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.modal {
    position: unset !important;
  height: auto !important;
  top: auto !important;
  left: auto !important;
  bottom: auto !important;
  right: auto !important;
  background: rgba(56, 123, 245, 0) !important;
  z-index: 100001 !important;
  box-shadow: none !important;
}

.ui.dimmer {
  background: rgba(31, 34, 38, 0.8);
}

.modal {
  z-index: 100001 !important;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 243px;
  overflow-y: hidden;
  padding-left: 0;
  width: calc(200px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #788d87;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
  z-index: 424;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.ui.toggle.checkbox .box::after, .ui.toggle.checkbox label::after {
    background: rgba(56, 123, 245, 1);
}
.ui.toggle.checkbox .box::before, .ui.toggle.checkbox label::before {
    background: hsl(217,82%,93%);
}
.ui.toggle.checkbox .box:hover::before, .ui.toggle.checkbox label:hover::before {
    background: hsl(217,82%,83%);
}

.ui.toggle.checkbox .box:active::before, .ui.toggle.checkbox label:active::before {
    background: hsl(217,82%,83%);
}

.ui.toggle.checkbox input:checked ~ .box::before, .ui.toggle.checkbox input:checked ~ label::before {
   background: rgba(56, 123, 245, 0.4) !important;
}

.ui.toggle.checkbox input:checked ~ .box:hover::before, .ui.toggle.checkbox input:checked ~ label:hover::before {
   background: rgba(56, 123, 245, 0.4) !important;
}

.ui.checkbox input.hidden + label {
    margin: 0px;
}



select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

}
.button_full_width:hover, .buttoncard:hover, .button_simple:hover{
  opacity: 0.75 !important
}
.button_full_width{
  height:auto !important;
  box-shadow: 0px 5px 30px 0px rgba(56, 123, 245, 0.15) !important;
}
.searchbutton{
  opacity:1 !important;
  border-radius:10px !important;
}
.searchbutton:hover{
  opacity: 0.7 !important;
  cursor: pointer ;
}

.buttonoutline:hover{
  background-color:rgba(56, 123, 245, 1) !important;
  color: white !important;
  border: 0px;
  opacity:1 !important;
}
.buttonoutline svg {
    fill: #387bf5;
}


.buttonoutline:hover svg{
  fill: white;
}
.ui.icon.button > .icon, .ui.icon.buttons .button > .icon  {
    line-height: initial;
}

.ui.labeled.icon.button > .icon, .ui.labeled.icon.buttons > .button > .icon {
    background: none;
}


.uploadButton {
    background: none;
    border: none;
    margin: 0px;
    padding: 0px;
    position: relative;
}
.uploadButton:hover{
  cursor: pointer !important;
}
.uploadButton:hover::after{
  content: url(ProfilbildHochladen.svg) !important;
  cursor: pointer !important;
}

.uploadButton::after {
  content: url(ProfilbildHochladenBefore.svg);
  display: block;
  position: absolute;
  width: 50%;
  top:0;
  left:0;
}


.button {
    cursor: pointer;
}

.button:active {
    opacity: 1;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: rgb(56, 123, 245, 0.5);
  border-radius: 1.3px;
  border: none;
}
input[type=range]::-webkit-slider-thumb {
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: rgb(56, 123, 245);
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: rgb(56, 123, 245, 0.5);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: rgb(56, 123, 245, 0.5);
  border-radius: 1.3px;
  border: none;
}
input[type=range]::-moz-range-thumb {
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: rgb(56, 123, 245);
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: rgb(56, 123, 245, 0.5);
  border: none;
  border-radius: 2.6px;
}
input[type=range]::-ms-fill-upper {
  background: rgb(56, 123, 245, 0.5);
  border: none;
  border-radius: 2.6px;
}
input[type=range]::-ms-thumb {
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 100px;
  background: rgb(56, 123, 245);
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: rgb(56, 123, 245, 0.5);
}
input[type=range]:focus::-ms-fill-upper {
  background: rgb(56, 123, 245, 0.5);
}

.control-dots li {
    width: 15px !important;
    height: 15px !important;
    outline: none;
}
a:hover{
  text-decoration: none;
}
.link_header a:hover{
  color: rgb(56, 123, 245) !important;
}
.button_lila button:hover{
  box-shadow: rgba(145, 56, 245, 0.3) 0px 0px 36px 10px !important;
}
.footer_alles a{
  color:#333333 !important;
  text-decoration: none !important;
}
.footer_alles a:hover{
  color: rgb(56, 123, 245) !important;
}
.footer_obere_zeile a{
  line-height: 40px !important;
}
.footer_menu a{
  margin-right:55px ;
}

@media only screen and (max-width: 500px){
  .footer_menu a{
    margin-right:0;
  }
}

.button_double_shadow_blue:hover{
  box-shadow: #d8e5fc 13px -13px 0px 0px, rgba(56, 123, 245, 0.5) 0px 0px 30px 3px !important;
  opacity: 1 !important;
}
.profil_menu_links:hover{
  font-weight: 600 !important;
}
.footer_link_social_media:hover{
  opacity: 0.6 !important;
}
.ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after{
  height: 28px !important;
  width: 28px !important;
}
.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before{
  height:32px !important;
}
.ui.toggle.checkbox .box, .ui.toggle.checkbox label{
  min-height: 32px !important;
}
.ui.toggle.checkbox input{
  height: 32px !important;
}
.ui.toggle.checkbox input:checked~.box:after, .ui.toggle.checkbox input:checked~label:after{
  left: 1.35rem !important;
  top: 2px !important;
}
.ui.toggle.checkbox label{
  padding-top: 0 !important;
}
.ui.toggle.checkbox input:checked ~ .box::before, .ui.toggle.checkbox input:checked ~ label::before{
   background-color: rgba(56, 123, 245, 0.4) !important;
}
.ui.toggle.checkbox input~.box:after, .ui.toggle.checkbox input~label:after{
  top:2px !important;
  left: 2px !important;
  box-shadow: rgba(56, 123, 245,0.3) 0 2px 6px 0 !important;
}
.ui.toggle.checkbox .box::before, .ui.toggle.checkbox label::before{
   background-color: rgba(0,0,0,.15) !important; 
}
.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before{
   background-color: rgba(56, 123, 245, 0.4) !important;
}


.ui.search.dropdown .menu {
  width:100% !important;
  max-height: 300px !important;
  margin-top: 11px !important;
}

@media (max-width: 500px) {
    .ui.search.dropdown .menu {
        max-height: 150px !important;
    }

}

input, select, textarea {
    outline: none;
}


.ui.multiple.dropdown > .label {
    background: #73a2f8;
    color: white;
    border: none;
    box-shadow: none;
    padding: 8px 24px;
    border-radius: 100px;
    font-weight: normal;
    height: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.ui.dropdown .menu > .item {
    max-width: min(600px, calc(100vw - 23px));
    text-overflow: ellipsis;
    overflow: hidden;
}

.ui.search.dropdown > .text {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: -12px;
}

.ui.label > .close.icon, .ui.label > .delete.icon {
    line-height: initial;
}

.ui.dropdown.userMenu .menu > .item, .nolengthrestriction.ui.dropdown .menu > .item {
    max-width: unset;
}


.ui.dropdown.userMenu .menu  {
    width: 250px;
    margin-top:18px;
    overflow:hidden;
    box-shadow: 0 0 65px 22px rgba(156, 156, 156,0.22);
}

.ui.dropdown.userMenu .menu > .item:hover {
    color:#387bf5;
    background-color: white;
    fill: #387bf5;
}
.ui.dropdown.userMenu .menu > .item:hover svg{
  fill: #387bf5;
}
.ui.dropdown.userMenu .menu > .item  svg{
  margin-left: 5px;
}

.ui.dropdown.dropdown_searchbar .menu > .item:hover {
  color:#387bf5 !important;
  background-color: white;
  fill: #387bf5;
}
.ui.dropdown.dropdown_searchbar .menu .selected.item{
  color: #387bf5 !important;
}

.ui.dropdown.moduleFilter .menu  {
    min-width: 300px;
    max-width: calc(100vw - 50px);
    width:unset !important;
}

#dropwdown_anfrage > .menu   {
  width: 250px !important;
  border-radius: 20px 0 20px 20px !important;
  margin-top:18px;
  overflow:hidden;
  box-shadow: 0 0 65px 22px rgba(156, 156, 156,0.22);
}

#multiselect_ort > .menu{
  max-height:unset !important;
}



#dropdown_mobile_menu_logged_out > .menu {
    width: 75vw !important;
    padding-top:30px !important;
    margin-right:-10px !important;
    border-radius: "0" !important;
    margin-top: 29px !important;
    border-radius: 20px 0 20px 20px !important;
    background-color: #387bf5 !important;
    height: 100vh !important;
    overflow:hidden !important;
    box-shadow: 0 0 65px 22px rgba(156, 156, 156,0.22) !important;
}

#dropdown_mobile_menu_logged_out  > .menu .item{
  font-size: 20px !important;
  text-align:center !important;
}
#dropdown_mobile_menu_logged_out  > .menu .item span{
  font-weight:500 !important;
  color:white !important;

}

#dropdown_mobile_menu_logged_out  > .menu .item:hover {
  color:white !important;
  background-color: #387bf5 !important;
}

#dropdown_mobile_menu_logged_out  > .menu .divider{
  margin-top:30px !important;
  margin-bottom:40px !important;
  border-color: #779bdd !important;
}



.popup{
  min-width:600px !important;
  border-radius:20px 30px 20px 30px !important;
  font-size:16px !important;
  padding: 25px !important;
  border:none !important;
  box-shadow: 0 0 65px 22px rgba(156, 156, 156,0.22) !important;
}
@media only screen and (max-width: 500px){
  .popup{
    min-width:86% !important;
    margin-left: 6% !important;
  }
}

.ui.popup.bottom.right {
    border-top-right-radius: 0px !important;
}

.ui.popup.top.right {
    border-bottom-right-radius: 0px !important;
}

.ui.popup::before {
    display: none;
}

.profile_pill_hover_wrap div:hover{
  white-space: normal !important;
  cursor: default !important;
}

.Such_Icon_Header_Link:hover .Such_Icon_Header{
  fill: #387bf5 !important;
}

.ui.dropdown .menu > .item:hover {
    background: none;
}

.ui.dropdown .menu > .item:hover span {
    color: #387bf5;
}

.hilfe_popup:hover {
  cursor: default !important;
}

.about_us_cards:hover{
  box-shadow:  0px 0px 55px 15px rgba(36, 52, 78, 0.1) !important;
  transition: box-shadow 0.4s;
}

.contact_möglichkeiten_cards:hover{
  box-shadow:  0px 0px 35px 15px rgba(36, 52, 78, 0.08) !important;
  transition: box-shadow 0.4s;
}

.accordion_titel:hover {
  color: #387bf5 !important;
}

.ui.dropdown .roundedright.menu,  .ui.dropdown.userMenu .menu {
    border-radius: 20px 0 20px 20px;
    box-shadow: 0 0 65px 22px rgba(156, 156, 156,0.22);
}

.ui.dropdown.upward .roundedright.menu {
    border-radius: 20px 20px 0px 20px;
}

.ui.dropdown .roundedleft.menu, .ui.search.dropdown .menu {
    border-radius: 0 20px 20px 20px;
    box-shadow: 0 0 65px 22px rgba(156, 156, 156,0.22);
}

.ui.dropdown.upward .roundedleft.menu, .ui.search.dropdown.upward .menu {
    border-radius: 20px 20px 20px 0px;
}


.ui.selection.active.dropdown .menu, .ui.selection.active.dropdown:hover .menu {
    border-color: inherit;
}

.fadedOut {
    opacity: 0.5;
    transition: opacity 0.6s;
}

.fadedOut:hover {
    opacity: 1;
}



.ui.checkbox.paymentRadio label{
    font-size: 20px;
    line-height: 20px;
}


.ui.dropdown .accept_reject.menu {
    width: 150px;
}

.ui.search.dropdown .menu {
    box-shadow: 0px 20px 25px 22px rgba(156, 156, 156,0.1);
}

.annhemen_button:hover{
  opacity: 0.85 !important;
}

.offline {
    transition: opacity 0.5s;
}

.strikeThrough {
    position: relative;
}

.strikeThrough::after{
    content: '';
    border-bottom: 3px solid #fcd1d1;
    position: absolute;
    left: 10px;
    top: 50%;
    width: calc(100% - 20px);
}
